<template>
  <!-- Error page-->
  <div>
    <nav-bar />
    <div class="misc-inner p-2 p-sm-3">
      <div class="misc-wrapper1 container">
        <div class=" text-justify1">
          <h2 class="main-title w-100 text-center">
            Zircly Zoom Integration App Documentation
          </h2>
          <br />
          <br />

          <p>
            In this document, we will discuss what is DCKAP Zircly and How the
            Zoom Integrator APP helps Zircly Tool
          </p>
          <h3>Zircly</h3>
          <p>
            Zircly is a Human Resource Management System, it is a tool to
            Manage the Recruitment Process and employees and more.
          </p>
          <h3>How this App helps our tool.</h3>
          <p>
            When a Candidate(Jobseeker) applied for a job, a candidate account
            in Zircly will be created for them by Zircly recruiter(HR). Then
            recruiter will schedule an interview for the candidate with the
            technical interviewer(employee).
          </p>
          <p>
            This App helps the recruiter to link their zoom account with
            Zircly and schedule a Zoom Meeting with given time and password
            and meeting recipients(Candidate and Interviewer).
          </p>
          <p>
            the meeting invite will be sent to them. Zircly application also
            will send emails to meeting recipients. we also have added
            screenshots and recordings of the functionality.
          </p>
          <h3>Authorize Zircly to use your Zoom</h3>
          <p>
            Zircly Zoom integrator can be installed in two places in Zircly website.
          </p>
          <ul>
            <li>In the Interview Create Page</li>
            <li>In the Profile Page</li>
          </ul>
          <h4>Install from Interview Create Page</h4>
          <br>
          <b-img fluid :src="InterviewZoom" alt="Install from Interview Create Page" />
          </br>
          <p>
            Whenever a recruiter creates an interview for a candidate(job seeker) with the employee, Zircly provides options to create an interview with Google and Zoom if they click zoom Zircly will check whether they installed BB Zoom integrator or not. if not, the "Login with zoom" button will be displayed, the user can click that and authorize Zircly to install in their account. and the Generated keys will be encryted and stored in DB for future scheduling purposes. with those keys, the interview event will be created in their Zoom Account. Tokens will be Encrypted with "Laravel's encryption" which is using AES-256 and AES-128 encryption , The Encrypted Tokens will be store in DB and the token is not exposed to the user in the client system.
          </p>
          <h4>Install from Profile Page</h4>
          <br>
          <b-img fluid :src="ManageZoomLogIn" alt="Install from Profile Page" />
          </br>
          <p>
            In the Profile Page, there will be a tab called "Manage Zoom", When Users enter that tab, Zircly will check whether they installed BB Zoom integrator or not. if not, the "Authorize Zircly to use My Zoom " button will be displayed and it will be clickable, the user can click that and authorize Zircly to install in their account. and the Generated keys will be Encrypted and stored in DB for future scheduling purposes. if they already installed Zircly Zoom integrator the button will be in disabled mode. Tokens will be Encrypted with "Laravel's encryption" which is using AES-256 and AES-128 encryption , The Encrypted Tokens will be store in DB and the token is not exposed to the user in the client system.
          </p>
          <h3>DeAuthorize Zircly to use My Zoom </h3>
          <p>
            Zircly Zoom integrator can be uninstalled from two places .
          </p>
          <ul>
            <li>Zircly Profile Page</li>
            <li>In Zoom Marketplace</li>
          </ul>
          <h4>Zircly Profile Page</h4>
          <br>
          <b-img fluid :src="ManageZoomLogOut" alt="Un-Install from Profile Page" />
          </br>
          <p>
            In the Profile Page, there will be a tab called "Manage Zoom", When Users enter that tab, Zircly will check whether they installed BB Zoom integrator or not. if they already Authorized Zircly to use their Zoom Account and installed the app the "Deauthorize" button will be displayed and it will be clickable, the user can click that and deauthorize Zircly to use their zoom account. and the user's zoom-related keys and details will be deleted from Zircly DB , the user have to authorize again to create interviews in Zircly.
          </p>
          <h4>UnInstalling the Zircly Zoom Connector In Zoom Marketplace</h4>
          <ol>
            <li>LogIn to your Zoom account and navigate to Zoom Marketplace</li>
            <li>Click <b>Manage -> Installed Apps </b> or Search <b>Zircly Zoom</b> app</li>
            <li>Click "Zircly Zoom" app and Click "Manage" </li>
            <li>Click "Remove" to uninstall the app from your account" </li>
          </ol>
          <p>Zircly can no longer do actions in your account</p>
        </div>
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from "bootstrap-vue";
import store from "@/store/index";
import { $themeConfig } from "@themeConfig";

const NavBar = () => import("@/components/NavBar.vue");

export default {
  components: {
    BLink,
    BButton,
    BImg,
    NavBar,
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/error.svg"),
      InterviewZoom:require("@/assets/images/zoom_doc/zoomfromInterview.png"),
      ManageZoomLogIn:require("@/assets/images/zoom_doc/loginZoomFromProfile.png"),
      ManageZoomLogOut:require("@/assets/images/zoom_doc/LogOutZoomfromProfile.png"),
    };
  },
  setup() {
    // App Name
    const { appName, appDarkLogo } = $themeConfig.app;
    return {
      appName,
      appDarkLogo,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
.brand-text {
  line-height: 42px;
  margin-bottom: 0;
}
</style>
